<template>
	<div class="new-insert">
		<!-- <Tabs :tab="tab" @checkTab="checkTab"></Tabs> -->
		<el-steps :active="tab" class="steps-wrap">
		  <el-step title="个人信息">
			  <div class="icon-box" slot="icon" @click="checkTab('Personal', true)">
				   <el-image class="insert-icon" :src="require('@/assets/images/personal-icon.png')"></el-image>
			  </div>
		  </el-step>
		  <el-step title="问卷信息">
			  <div class="icon-box" slot="icon" @click="checkTab('Questions', true)">
				  <el-image v-if="step == 'Questions' || type == 'update' || step == 'myBodily' || step == 'Biochemical' || step == 'Newborn' || step == 'Puerpera'" class="insert-icon" :src="require('@/assets/images/quest-int.png')"></el-image>
				  <el-image v-else class="insert-icon" :src="require('@/assets/images/quest.png')"></el-image>
				  <!-- <i class="el-icon-user-solid"></i> -->
			  </div>
		  </el-step>
		  <el-step title="体型信息">
			  <div class="icon-box" slot="icon" @click="checkTab('myBodily', true)">
				  <el-image v-if="step == 'myBodily' || type == 'update' || step == 'Biochemical' || step == 'Newborn' || step == 'Puerpera'" class="insert-icon" :src="require('@/assets/images/shape-int.png')"></el-image>
				  <el-image v-else class="insert-icon" :src="require('@/assets/images/shape.png')"></el-image>
				  <!-- <i class="el-icon-user-solid"></i> -->
			  </div>
		  </el-step>
		  <el-step title="妈妈信息">
				<div class="icon-box" slot="icon" @click="checkTab('Puerpera', true)">
					<el-image v-if="step == 'Puerpera' || type == 'update' || step == 'Biochemical' || step == 'Newborn'" class="insert-icon" :src="require('@/assets/images/gravida-int.png')"></el-image>
					<el-image v-else class="insert-icon" :src="require('@/assets/images/gravida.png')"></el-image>
					  <!-- <i class="el-icon-user-solid"></i> -->
				</div>
		  </el-step>
		  <el-step title="宝宝信息">
				<div class="icon-box" slot="icon" @click="checkTab('Newborn', true)">
					<el-image v-if="step == 'Newborn' || type == 'update' || step == 'Biochemical'" class="insert-icon" :src="require('@/assets/images/newborn-int.png')"></el-image>
					<el-image v-else class="insert-icon" :src="require('@/assets/images/newborn.png')"></el-image>
					  <!-- <i class="el-icon-user-solid"></i> -->
				</div>
		  </el-step>
		  <el-step title="生化信息">
			  <div class="icon-box" slot="icon" @click="checkTab('Biochemical', true)">
				  <el-image v-if="step == 'Biochemical' || type == 'update'" class="insert-icon" :src="require('@/assets/images/bioch-int.png')"></el-image>
				  <el-image v-else class="insert-icon" :src="require('@/assets/images/bioch.png')"></el-image>
				  <!-- <i class="el-icon-user-solid"></i> -->
			  </div>
		  </el-step>
		</el-steps>
		
		<router-view :type="type" :is="step" :clearData="clearData" :formData="formData" @stopQuery="$emit('stopQuery')" @checkTab="checkTab"></router-view>
	</div>
</template>

<script>
	import { packageOpen } from '@/utils/axios'
	// const Tabs = () => import('./Tabs');
	const Personal = () => import('./Personal');
	const Questions = () => import('./Questions');
	const myBodily = () => import('./Bodily');
	const Newborn = () => import('./Newborn');
	const Puerpera = () => import('./Puerpera');
	const Biochemical = () => import('./Biochemical');
	
	export default{
		name: 'newInsert',
		data() {
			return {
				tab: 1 ,//默认从个人信息开始填写
				step: 'Personal',
				formData: {},
				defFormData: { 
					mobile: '',
					yyuserid: '',
					sex: '',
					birth: '',
					examdate: '',
					examplace: '',
					package: '',
					
					corp: '',
					
					height: '',
					weight: '',
					// bust: '',
					waist: '',
					hip: '',
					fat: '',
					
					area: '',
					drink: '',
					smoke: '',
					habeat: '',
					vege: '',
					habsport: '',
					sleep: '',
					disease: [
						{"kdis": "K01", "level": "0"},
						{"kdis": "K02", "level": "0"},
						{"kdis": "K03", "level": "0"},
						{"kdis": "K04", "level": "0"},
						{"kdis": "K05", "level": "0"},
						{"kdis": "K06", "level": "0"},
						{"kdis": "K07", "level": "0"},
						{"kdis": "K08", "level": "0"},
						{"kdis": "K09", "level": "0"},
						{"kdis": "K10", "level": "0"},
						{"kdis": "K11", "level": "0"},
						{"kdis": "K12", "level": "0"},
						{"kdis": "K13", "level": "0"},
						{"kdis": "K14", "level": "0"},
						{"kdis": "K15", "level": "0"},
						{"kdis": "K16", "level": "0"},
						{"kdis": "K17", "level": "0"},
						{"kdis": "K18", "level": "0"},
						{"kdis": "K19", "level": "0"}
					],
					oper:[],
					famdis:[],
					
					preweek: '',
					preweight: '',
					prewaist: '',
					// prebust: '',
					prehip: '',
					prefat: '',
					
					bsex: '',
					mage: '',
					week: '',
					bweight: '',
					aps1: '',
					
					sbp: '',
					dbp: '',
					
					examine: [
						{"ne": "WBC", "ve": "", "ut": "A"},
						{"ne": "HGB", "ve": "", "ut": "A"},
						{"ne": "PLT", "ve": "", "ut": "A"},
						{"ne": "FG", "ve": "", "ut": "B"},
						{"ne": "HBA1C", "ve": "", "ut": "A"},
						{"ne": "CHOL", "ve": "", "ut": "B"},
						{"ne": "LDLC", "ve": "", "ut": "B"},
						{"ne": "HDLC", "ve": "", "ut": "B"},
						{"ne": "TG", "ve": "", "ut": "B"},
						{"ne": "GOT", "ve": "", "ut": "A"},
						{"ne": "GPT", "ve": "", "ut": "A"},
						{"ne": "ALP", "ve": "", "ut": "A"},
						{"ne": "ALB", "ve": "", "ut": "B"},
						{"ne": "CRE", "ve": "", "ut": "B"},
						{"ne": "UA", "ve": "", "ut": "B"},
						{"ne": "EGFR", "ve": "", "ut": "A"}
					],
					
					predis2: [],
					
					operator: '',// 当前后台操作人手机号
					
					sbpCombo: true,// 产品组合勾选(前台使用，用来确认勾选产品组合-基础套餐体检)
				}
			}
		},
		components: {
			// Tabs,
			Personal,
			Questions,
			myBodily,
			Newborn,
			Puerpera,
			Biochemical
		},
		props: [ 'type', 'dataId', 'clearData' ],
		mounted() {
			this.formData = this.defFormData
			if(this.dataId) {
				packageOpen({act: 'query', id: this.dataId}).then((res) => {
					if(res.code == 200) {
						let dataBase = JSON.parse(window.atob(res.data))
						let input_data = dataBase.input_data
						let babyinf = dataBase.input_data.babyinf
						let preinf = dataBase.input_data.preinf
						let data = {...dataBase, ...input_data, ...preinf}
						data = {...data, ...babyinf}
						delete data.input_data
						delete data.babyinf
						delete data.preinf
						this.formData = data
						if(this.formData.yy_user_id) {
							this.formData.yyuserid = this.formData.yy_user_id
						}
					}
				}) 
				if(this.type == 'update') {
					this.tab = 6
					return
				}
			}
		},
		methods: {
			//切换tab 参数 tab组件名
			checkTab(tab, isTab) {
				if(this.type == 'insert' && isTab) {
					return;
				}
				if(this.type == 'update') {
					this.tab = 6
					this.step = tab
					return
				}
				switch (tab) {
					case 'Personal' :
					this.tab = 1
					break
					case 'Questions' :
					this.tab = 2
					break
					case 'myBodily' :
					this.tab = 3
					break
					case 'Puerpera' :
					this.tab = 4
					break
					case 'Newborn' :
					this.tab = 5
					break
					case 'Biochemical' :
					this.tab = 6
					break
				}
				this.step = tab
			}
		}
	}
</script>

<style scoped>
	.new-insert >>> .el-step__icon {
		width: 36px;
		height: 36px;
		background-color: #F2F4FA;
		border-radius: 50%;
		cursor: pointer;
	}
	.new-insert >>> .el-step__icon.is-text {
		border: none;
	}
	.new-insert >>> .el-step.is-horizontal .el-step__line {
		height: 6px;
		top: 16px;
		background-color: #F2F4FA;
	}
	.new-insert >>> .el-step__line-inner {
		height: 6px;
		top: 16px;
		background: #6883FB;
	}
	.new-insert >>> .el-step__title {
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #525866;
		line-height: 16px;
	}
	.new-insert >>> .el-step__main {
		position: relative;
		left: -6px;
		margin-top: 8px;
	}
</style>

<style lang="scss" scoped>
	.steps-wrap {
		width: 752px;
		margin: 16px auto 0;
	}
	.icon-box {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 36px;
		height: 36px;
		// background-color: #6883FB;
		border-radius: 50%;
	}
	
	.el-icon-user-solid {
		font-size: 20px;
		color: #fff;
	}
	
	.insert-icon {
		width: 36px;
		height: 36px;
	}
</style>
