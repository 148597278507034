<template>
  <div class="role">
    <div class="role-wrap" v-if="!isEdit">
      <div class="qjc-flex query-wrap">
        <el-input class="search-input" v-model="nowMobile" @input="accountInput" :placeholder="$t('table.t43')">
          <div class="search-icon" slot="prefix">
            <i class="el-icon-search"></i>
          </div>
        </el-input>
        <el-button @click="query" :loading="submiting" class="query-btn qjc-fts-16">{{ $t('table.t39') }}</el-button>
        <el-button @click="handleRet" class="query-btn qjc-fts-16">{{ $t('table.t40') }}</el-button>
      </div>
      <el-table :data="user" class="list">

        <!-- :height="mainHeight-214+'px'" -->
        <template slot="empty">
          <div class="empty-wrap">
            <el-image class="empty-img" :src="require('@/assets/images/empty-history.png')"></el-image>
            <div class="empty-text">{{ $t('table.t52') }}</div>
          </div>
        </template>
        <el-table-column prop="id" width="100px" :label="$t('table.t1')" class-name="id">

        </el-table-column>
        <el-table-column prop="mobile" :label="$t('table.t2')" class-name="mobile">
          <template slot-scope="scope">
            {{ scope.row.mobile ? scope.row.mobile : '--' }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('table.t3')" class-name="goods_name" prop="goods_name">
          <template slot-scope="scope">
            <span>{{ scope.row.type == 'AIZH' ? '[AI]Smart Health' : scope.row.type == 'AIFB' ? `[AI]Smart Fracture -
              Risk` : scope.row.type == 'AIBG' ? '[AI]VIP Health Risk Examination' : scope.row.type == 'M22'
              ?
              '[AI]4-Hypers Analysis' : scope.row.type == 'AIMB' ? '[AI]Postpartum Risk (Mother & Newborn)' :
                scope.row.goods_name }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="sex" :label="$t('table.t4')" class-name="sex">
          <template slot-scope="scope">
            {{ scope.row.sex == '1' ? $t('open.t14') : scope.row.sex == '2' ? $t('open.t15') : '' }}
          </template>
        </el-table-column>

        <el-table-column prop="birth" :label="$t('table.t5')" class-name="birth">
        </el-table-column>
        <el-table-column prop="examdate" :label="$t('table.t6')" class-name="examdate">
          <template slot-scope="scope">
            {{ scope.row.examdate ? scope.row.examdate : '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="is_finish" :label="$t('table.t7')" class-name="is_finish">
          <template slot-scope="scope">
            <div class="finish-not" v-if="scope.row.is_finish == 1">
              {{ $t('table.t37') }}
            </div>
            <div class="finished" v-if="scope.row.is_finish == 80">
              {{ $t('table.t36') }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="action" :label="$t('table.t9')" class-name="action">
          <template slot-scope="scope">
            <div class="action-btn" v-if="scope.row.is_finish == 1 && scope.row.goods_id != '3'"
              @click="handleUpdateBo(scope.row)">
              Input
            </div>
            <div class="action-btn" v-if="scope.row.is_finish == 80" @click="handleUpdate(scope.row)">
              {{ $t('table.t38') }}
            </div>

          </template>
        </el-table-column>



      </el-table>

      <!-- 分页 -->
      <el-pagination class="pagination qjc-texta-c" hide-on-single-page :current-page.sync="pager.page"
        :page-count="pager.pagecount" :page-size="pager.pagesize" :total="pager.count" @current-change="pageChange"
        prev-text="last" next-text="next" layout="total, prev, pager, next,jumper">
      </el-pagination>
    </div>

    <div class="open-wrap" v-if="isEdit">
      <new-question :isBo="isBo" :dataId="id" type="update" :cancelEdit="cancelDate"></new-question>

    </div>
  </div>
</template>

<script>
import { adminRole, printBatchQuery, mainDataQuery } from "@/utils/axios"
import NewInsert from '@/components/NewInsert'
import NewInsertMy from '@/components/NewInsert/muying'
import NewQuestion from '@/components/NewQuestion'

import { Base64 } from 'js-base64'


export default {
  name: 'adminDataUpdate',
  components: {
    NewInsert,
    NewInsertMy, NewQuestion
  },
  data () {
    return {
      isEdit: false,
      isMuying: false,
      id: '',
      mainHeight: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 60,
      formData: {
        mobile: '',
        yyuserid: '',
        sex: '',
        birth: '',
        examdate: '',
        examplace: '',
        package: '',

        height: '',
        weight: '',
        bust: '',
        waist: '',
        hip: '',
        fat: '',

        area: '',
        marry: '',
        childcnt: '',
        drink: '',
        smoke: '',
        habeat: '',
        vege: '',
        habsport: '',
        sleep: '',
        disease: [
          { "kdis": "K01", "level": "0" },
          { "kdis": "K02", "level": "0" },
          { "kdis": "K03", "level": "0" },
          { "kdis": "K04", "level": "0" },
          { "kdis": "K05", "level": "0" },
          { "kdis": "K06", "level": "0" },
          { "kdis": "K07", "level": "0" },
          { "kdis": "K08", "level": "0" },
          { "kdis": "K09", "level": "0" },
          { "kdis": "K10", "level": "0" },
          { "kdis": "K11", "level": "0" },
          { "kdis": "K12", "level": "0" },
          { "kdis": "K13", "level": "0" },
          { "kdis": "K14", "level": "0" },
          { "kdis": "K15", "level": "0" },
          { "kdis": "K16", "level": "0" },
          { "kdis": "K17", "level": "0" },
          { "kdis": "K18", "level": "0" },
          { "kdis": "K19", "level": "0" }
        ],
        oper: [],
        famdis: [],

        examine: [
          { "ne": "WBC", "ve": "", "ut": "A" },
          { "ne": "HGB", "ve": "", "ut": "A" },
          { "ne": "PLT", "ve": "", "ut": "A" },
          { "ne": "FG", "ve": "", "ut": "B" },
          { "ne": "HBA1C", "ve": "", "ut": "A" },
          { "ne": "CHOL", "ve": "", "ut": "B" },
          { "ne": "LDLC", "ve": "", "ut": "B" },
          { "ne": "HDLC", "ve": "", "ut": "B" },
          { "ne": "TG", "ve": "", "ut": "B" },
          { "ne": "GOT", "ve": "", "ut": "A" },
          { "ne": "GPT", "ve": "", "ut": "A" },
          { "ne": "ALP", "ve": "", "ut": "A" },
          { "ne": "ALB", "ve": "", "ut": "B" },
          { "ne": "CRE", "ve": "", "ut": "B" },
          { "ne": "UA", "ve": "", "ut": "B" },
          { "ne": "EGFR", "ve": "", "ut": "A" }
        ],

        operator: ''// 当前后台操作人手机号
      },
      rules: {
        name: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ],
        power: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ]
      },
      user: [],// 客户信息

      submiting: false,//正在请求

      pager: {
        count: 0,
        page: 1,// 默认第一页
        pagecount: 0,
        pagesize: 12//默认一页12条数据
      },

      nowMobile: '',// 存储当前已查询企业用户id或手机号
      isBo: false,
    }
  },
  inject: ['handleRefresh'],
  mounted () {
    this.getList()
  },
  watch: {

  },
  methods: {
    cancelDate () {
      this.isEdit = false;
      this.getList()

    },
    accountInput (val) {
      let codeReg = new RegExp("[A-Za-z0-9]+");
      let len = val.length;
      let str = '';
      for (let i = 0; i < len; i++) {
        if (codeReg.test(val[i])) {
          str += val[i]
        }
      }
      this.nowMobile = str
      // this.getList();
    },
    clearData () {
      this.formData = {
        mobile: '',
        yyuserid: '',
        sex: '',
        birth: '',
        examdate: '',
        examplace: '',
        package: '',

        height: '',
        weight: '',
        bust: '',
        waist: '',
        hip: '',
        fat: '',

        area: '',
        marry: '',
        childcnt: '',
        drink: '',
        smoke: '',
        habeat: '',
        vege: '',
        habsport: '',
        sleep: '',
        disease: [
          { "kdis": "K01", "level": "0" },
          { "kdis": "K02", "level": "0" },
          { "kdis": "K03", "level": "0" },
          { "kdis": "K04", "level": "0" },
          { "kdis": "K05", "level": "0" },
          { "kdis": "K06", "level": "0" },
          { "kdis": "K07", "level": "0" },
          { "kdis": "K08", "level": "0" },
          { "kdis": "K09", "level": "0" },
          { "kdis": "K10", "level": "0" },
          { "kdis": "K11", "level": "0" },
          { "kdis": "K12", "level": "0" },
          { "kdis": "K13", "level": "0" }
        ],
        oper: [],
        famdis: [],

        examine: [
          { "ne": "WBC", "ve": "", "ut": "A" },
          { "ne": "HGB", "ve": "", "ut": "A" },
          { "ne": "PLT", "ve": "", "ut": "A" },
          { "ne": "FG", "ve": "", "ut": "B" },
          { "ne": "HBA1C", "ve": "", "ut": "A" },
          { "ne": "CHOL", "ve": "", "ut": "B" },
          { "ne": "LDLC", "ve": "", "ut": "B" },
          { "ne": "HDLC", "ve": "", "ut": "B" },
          { "ne": "TG", "ve": "", "ut": "B" },
          { "ne": "GOT", "ve": "", "ut": "A" },
          { "ne": "GPT", "ve": "", "ut": "A" },
          { "ne": "ALP", "ve": "", "ut": "A" },
          { "ne": "ALB", "ve": "", "ut": "B" },
          { "ne": "CRE", "ve": "", "ut": "B" },
          { "ne": "UA", "ve": "", "ut": "B" },
          { "ne": "EGFR", "ve": "", "ut": "A" }
        ],

        operator: '',// 当前后台操作人手机号
      }
    },

    query () {
      // 正在请求
      if (this.nowMobile.trim() != '') {
        this.submiting == true;
        this.pager.page = 1;
        this.getList();
      }
    },
    handleRet () {
      this.nowMobile = '';
      this.pager.page = 1;
      this.getList();
    },
    pageChange (page) {
      this.pager.page = page;
      this.getList();
    },

    getList () {
      mainDataQuery({
        act: 'data-update',
        mobile: this.nowMobile,
        page: this.pager.page,
        pagesize: this.pager.pagesize
      }).then(res => {
        // console.log(res);
        if (res.code == 200) {
          this.submiting == false;
          const data = JSON.parse(Base64.decode(res.data))
          console.log("data", data);
          if (data.data.length == 0) {
            this.user = [];
            this.pager = {
              count: 0,
              page: 1,// 默认第一页
              pagecount: 0,
              pagesize: 12//默认一页12条数据
            }
            // this.$myAlert({
            // 	title: res.msg,
            // 	callback: () => {
            // 		this.hanleLoadAll()
            // 	}
            // });
          } else {
            this.user = data.data;
            //分页
            this.pager = data.pager;

            // 数值可能为字符串，需转换为number
            for (var key in this.pager) {
              this.pager[key] = parseInt(this.pager[key]);
            }
          }
        } else {
          this.submiting == false;
          this.$myAlert({
            type: 'fail',
            content: res.msg,
          });
        }
      });
    },
    hanleLoadAll () {
      this.nowMobile = '',
        this.getList();
    },
    handleUpdateBo (row) {
      this.id = row.id
      this.isBo = true
      this.isEdit = true;
    },

    handleUpdate (row) {
      // 数据修改
      this.id = row.id
      this.isBo = false
      if (row.goods_id == '3') {
        this.isMuying = true
      } else {
        this.isMuying = false
      }
      this.isEdit = true;
    },

  }
}
</script>

<style scoped>
/* .role >>> .el-table th {
		height: 38px;
		padding: 0;
		background-color: #F7F9FC;
	}
	.role >>> .el-table td {
		height: 38px;
		padding: 0;
	} */

.query-wrap>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

.role>>>.el-table--fit {
  border-bottom: 1px solid #eeeeee !important;
}

.role>>>.el-table__fixed-right::before,
.el-table__fixed::before {
  height: 0 !important;
}

.role>>>thead th {
  /* display: inline-block; */
  color: #40444D;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  border-bottom: none;
  background: #F2F4FA;
}
</style>
<style lang="scss" scoped>
.role {
  position: relative;
  padding: 24px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border: 12px solid #F2F4FA;

  .role-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .query-btn {
    width: 72px;
    height: 36px;
    line-height: 36px;
    font-size: 15px;
    padding: 0;
    margin-left: 16px;
    border: none;
    color: #fff;
  }

  .list {
    margin-top: 16px;

    &::before {
      display: none;
    }

    .el-button {
      font-size: 14px;
      padding: 6px 8px;
      font-weight: 400;

      +.el-button {
        margin-left: 24px;
      }
    }

    .package-cell {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .action-btn {
    font-size: 14px;
    // font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 400;
    color: #6883FB;
    cursor: pointer;
    display: inline-block;

    &:hover {
      color: #7a92fb;
    }
  }

  .action-btn-end {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #B4BBCC;
    cursor: pointer;
  }

  .foot-pagination {
    // position: absolute;
    // bottom: 0;
    width: 100%;
    padding-bottom: 20px;
    // height: 80px;
  }

  .pagination {
    // position: absolute;
    // bottom: 0;
    width: 100%;
    margin-top: 8px;
    text-align: center;
  }

  .search-input {
    // width: 458px;
    width: 226px;
    height: 34px;
  }

  .search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 36px;
  }

  .el-dialog {

    .dialog-title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #293033;
      text-align: center;
    }

    .all-power {
      -webkit-appearance: none;
      background-color: #FFF;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
      width: 100%;
    }

    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: center;

      .cancel-btn {
        width: 94px;
        height: 34px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #6883FB;
        border: 1px solid #6883FB;
        border-radius: 17px;
        background-color: #fff;
      }

      .submit-btn {
        width: 94px;
        height: 34px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #fff;
        border: none;
        border-radius: 17px;
        background-color: #6883FB;
        margin-left: 16px;
      }

      .el-button {
        padding: 9px 15px;
      }
    }
  }
}

.finish-not {
  position: relative;
  padding-left: 12px;

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #FF5219;
  }
}

.finished {
  position: relative;
  padding-left: 12px;

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #38E019;
  }
}

.empty-wrap {
  padding: 100px 0;
}

.empty-img {
  width: 338px;
  height: 218px;
}
</style>
